<template>
    <div>
        <div id="checkout">
            <!-- Checkout will insert the payment form here -->
        </div>
    </div>
</template>

<script>
import { checkApplication } from "../../api/eae";
import {
    isTestUser,
} from '../../utils/store'
export default {
    data() {
        return {
            application_id: this.$route.query.application_id,
            user_id: "",
            amount: "",
            currency: "",
        };
    },
    mounted() {
        this.requestApplication()
    },
    methods: {

        requestApplication() {
            if (true) {
                checkApplication(this.application_id).then((res) => {
                    let applicationInfo = res.data.data
                    let project_price_currency = applicationInfo.project_info.project_price_currency
                    if (project_price_currency == 'USD') {
                        project_price_currency = 'usd'
                    }
                    else if (project_price_currency == 'SGD') {
                        project_price_currency = 'sgd'
                    } else {
                        project_price_currency = 'cny'
                    }
                    this.handleApplicationData(this.application_id, applicationInfo, project_price_currency)
                })
            }
        },
        handleApplicationData(application_id, application, currency) {
            let amount = application.service_fee + application.total_price
            if(isTestUser()){
                amount  = 0.5
            }
            let line_items = [{
                price_data: {
                    currency: currency,
                    product_data: {
                        name: application.project_info.project_ename,
                    },
                    unit_amount: amount * 100,
                },
                quantity: 1,
            }]
            this.pay(application_id, line_items);
        },

        pay(application_id, line_items) {
            // Initialize Stripe.js
            const stripe = Stripe(
                "pk_live_51P128qP6BucL4HOz2SJY6gWLATlcK5rGdNTnePD780jhsy6ZLf7ZxIHxRE1oBLk9pchjvIoqHUwnPPGB10DmtGOO00yikhqAr0"
            );

            initialize();
            // Fetch Checkout Session and retrieve the client secret
            async function initialize() {
                const fetchClientSecret = async () => {
                    const response = await fetch(
                        "https://web-backend.reachable-edu.com/Stripe/EAEProgramEmbedPay.php",
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                application_id: application_id,
                                line_items: line_items,
                            }),
                        }
                    );
                    let result = await response.json();
                    const { clientSecret } = result;
                    return clientSecret;
                };

                // Initialize Checkout
                const checkout = await stripe.initEmbeddedCheckout({
                    fetchClientSecret,
                });

                // Mount Checkout
                checkout.mount("#checkout");
            }
        },
    },
};
</script>

<style></style>